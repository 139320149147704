export default {
  id: null,
  language_id: null,
  level: {
    listening: null,
    speaking: null,
    conversation: null,
    reading: null,
    writing: null
  }
};
