export default {
  id: null,
  user_id: null,
  title: null,
  company_name: null,
  start_year: null,
  end_year: null,
  task_1: null,
  task_2: null,
  task_3: null,
  task_4: null,
  task_5: null,
  achievement_1: null,
  achievement_2: null,
  achievement_3: null,
  achievement_4: null,
  achievement_5: null,
  location: null,
  reference_name: null,
  reference_email: null,
  reference_phone: null
};
