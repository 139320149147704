export default {
  firebase: {
    apiKey: "AIzaSyA9ChTUpE5hTVcy3UxsW6DHZKCeiNT1y_Y",
    authDomain: "app-gerardg.firebaseapp.com",
    databaseURL: "https://app-gerardg.firebaseio.com",
    projectId: "app-gerardg",
    storageBucket: "app-gerardg.appspot.com",
    messagingSenderId: "92269346049",
    appId: "1:92269346049:web:0aaf279cb3baa77a722f00",
    measurementId: "G-507PZB6CGE"
  },
  stripe: {
    publishableKey: "",
    secretKey: ""
  }
};
